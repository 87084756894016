.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    height: 50vh;
    justify-content: space-between;
    margin: 10vh;
    transition: 0.3s;

    &:hover {
        transform: scale(1.1);
        cursor: pointer;
    }

    h3 {
        color: var(--text-color2);
        font-weight: 100;
    }
}

.pic {
    height: 90%;
    width: 100%;
}

@media screen and (max-width: 991px) and (orientation: portrait) {
    .container {
       
        width: 85%;
        height: 35vh;
    }
        
    
    .pic {
        height: 80%;
        width: 100%;
    }
    
}
@media screen and (max-width: 400px) and (orientation: portrait) {
.container {
height: 30vh;
}

 }

@media screen and (max-width: 991px) and (orientation: landscape) {
    .container {
        width: 60%;
        height: 70vh;
    }
}

