:root {
    --primary : 31, 34, 32 ;
    --secondary: #262C26 ;
    --text-color1: #707070;
    --text-color2: #ffffff;
    --link-color: #B4B4B4;
    --button1: #3F3D3D;
    --hover: #324b32 ;
    --error: #eb2f06;
    --divider: #ecf0f1;
    --font-family1: 'Francois One', sans-serif;
    --font-family2: 'Forum', cursive;

}