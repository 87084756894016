.container {
    display: flex;
    width: 100%;
    background-color: #1F2220;
    flex-direction: column;
    align-items: center;

    h2 {
        margin-top: 10vh;
    }
}


.body {
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
}

