.container {
    display: flex;
    width: 100%;
    background-color: #1F2220;
    flex-direction: column;
    align-items: center;
}

.body {
    display: flex;
    width: 90%;
    flex-direction: column;

  
   
}

.informations {

    display: flex;
    flex-direction: column;
    margin-top: 10vh;
   

}

.informationsContents {
    display: flex;
    justify-content: space-between;
    height: 30vh;
    margin-top: 5vh;
    margin-bottom: 10vh;
    

    h5 {
        color: var(--text-color2);
        font-family: var(--font-family2);
        font-size: 1.3em;
    }

}

.infos {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60%;
}

.map {
    display: flex;
    position: absolute;
    right: 5%;
    top: 60%;
    width: 40%;
}

.map2 {
    display: none;
    position: absolute;
    right: 5%;
    top: 60%;
    width: 40%; 
}

.greenBackground {
    width: 100%;
    background-color: var(--secondary);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 8vh;
    justify-content: space-between;
    margin-top: 3vh;
    margin-bottom: 8vh;
}

.contact {
    display: flex;
    height: 80vh;
    justify-content: space-between;
    margin-bottom: 5vh;

    form {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        height: 70vh;

        textarea {
            min-width: 100%;
            max-width: 100%;
            height: 25vh;
            background: none;
            border: none;
            border-bottom: 2px solid var(--text-color1);
            color: var(--text-color1);
            font-size: 1.2em;
            font-family: var(--font-family2);
            margin-top: 5vh;

            outline: none; 
    transition: border-bottom 0.3s ease;
    transition: color 0.3s ease;
        }
        textarea:focus {
            border-bottom: 2px solid var(--text-color2); 
            color: var(--text-color2);
        }
    }
}


.contactPicture {
    height: 100%;
}

.contactContents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 50%;
}

.contactText {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 5vh;
    h5 {
        font-family: var(--font-family2);
        color: var(--text-color2);
        font-weight: 100;
        font-size: 1.2em;
    }
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.input {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 40%;
}

.input input {
    height: 7vh;
    color: var(--text-color1);
    font-size: 1.2em;
    font-family: var(--font-family2);
    border: none;
    background: transparent ;
    border-bottom: 2px solid var(--text-color1);
    width: 100%;
    outline: none; 
    transition: border-bottom 0.3s ease;
    transition: color 0.3s ease;
    
}

.input input:focus {
    border-bottom: 2px solid var(--text-color2); 
    color: var(--text-color2);
}

.input input:-webkit-autofill,
.input input:-webkit-autofill:hover, 
.input input:-webkit-autofill:focus, 
.input input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--text-color1);
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
  
}

.withError {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.withError2 {
    width: 100%;
    display: flex;
    flex-direction: column;
   
}

.error {
    color: var(--error);
}


.checkbox {
    display: flex;
    width: 100%;

    input {
        width: 2vh;
        margin-right: 3vh;
    }
    p {
        padding-right: 15vh;
        font-size: 1em;
    }
}

@media screen and (max-width: 991px) {

    .body {
        width: 85%;
    }

   

    
    .informationsContents {
        flex-direction: column;
        margin-bottom: 30vh;
        display: block;
    }

    .map {
        display: none;
    }

    .map2 {
        display: flex;
        position:sticky;
        width: 85%;
        left: 7.5%;
        margin-top: 20%;
      
        

    }

    .contact {
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 150vh;

        form {
            height: 100%;
            
           
        }
    }

    .contactPicture {
        height: 30%;
    }

    .contactText {
        height: 5vh;
        margin-bottom: 10vh;
    }

    .contactContents {
        width: 100%;
        height: 65%;
        justify-content: start;
    }

    .input {
        flex-direction: column;
        flex-wrap: nowrap;
        height: 70%;
        width: 100%;

        input {
            width: 100%;
        }
    }

    .checkbox {
      
        p {
            padding-right: 0;
            
        }

        input {
            width: 10%;
        }
    }


}

@media screen and (max-width: 991px) and (orientation: landscape) {
    .infos {
        height: 30vh;
    }

    .contact {
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 230vh;

        form {

            textarea {
                height: 45vh;
            }
        }
    }

    .contactText{
       margin-top: 10vh;
    }

    .contactContents {
        height: 70%;
    }

    .input {
    
        height:80%;
        

        input {
            width: 100%;
        }
    }

    .checkbox {
        input {
            width: 3%;
        }
    }
}