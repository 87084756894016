.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #1F2220;
    img {
        width: 40% ;
        object-fit: contain; 
    }

    p {
        font-size: 1.6em;
        margin-top: 2%;
    }



    .buttons {
        margin-top: 10%;
        width: 40%;
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 991px) and (orientation: portrait) {
    
    .container {

        h2 , p {
            width: 80%;
            text-align: center;
            margin: 5%;
        }
      
        img {
            margin-top: 20%;
            width: 80%;
        }

        .buttons {
           
            flex-direction: column;
            height: 15vh;
            width: 100%;
            justify-content: space-between;
            align-items: center;
           
        }
    }
}

@media screen and (max-width: 991px) and (orientation: landscape) {
    .container {

        img {
            margin-top: 5%;
        }
        .buttons {
            margin-bottom: 5%;
        }
    }
}