.empty {
    width: 20vh;
    border-top: 2px solid white;
}

@media screen and (max-width: 991px) {
    .empty {
        width: 10vh;
    }
}

@media screen and (orientation: landscape) and (max-width: 991px) {

     .empty {
        width: 30vh;
     }
     }