.container {
    display: flex;
    flex-direction: column;
    min-height: 20vh;

    h3 , p {
        margin-top: 1.5vh;
        margin-bottom: 1.5vh;
    }
}

@media screen and ( orientation: landscape) and (max-width: 991px) {
    .container {
        min-height: 60vh;
    }
}





