.container {
    display: flex;
    flex-direction: column;
    width: 25vh;
    border: 2px solid var(--text-color1);
    height: 17vh;
    background-color: var(--secondary);
    align-items: center;
    justify-content: space-around;
    transition: 0.3s;
    cursor: pointer;
   
    ;

    img {
        height: 40%;
        
    }

    p {
        padding: 5%;
       text-align: center;
       font-size: 1.1em;
       font-family: var(--font-family1);
    }
}

@keyframes borderAnimation {
    0% {
      border-image-source: linear-gradient(0deg, #ffffff, #ffffff); /* Couleur initiale */
      ;
    
    }
    25% {
      border-image-source: linear-gradient(0deg, #ffffff, var(--text-color1)); /* Couleur finale à mi-chemin */
      ;
    }
    50% {
      border-image-source: linear-gradient(0deg, var(--text-color1), var(--text-color1)); /* Couleur finale */
      ;
    }
    75% {
        border-image-source: linear-gradient(0deg, #ffffff, var(--text-color1)); /* Couleur finale à mi-chemin */
        ;
    }
    100% {
        border-image-source: linear-gradient(0deg, #ffffff, #ffffff); /* Couleur initiale */
        ;
      }
      
  }

.actif {
    border: 2px solid transparent;
    border-image-slice: 1;
    animation: borderAnimation 2s linear infinite;
    transform: scale(1.05);
    transition: 0.5s;
      
}

@media screen and (max-width: 991px){
  .container {
    margin: 5%;
  }
}