.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
   margin-top: 10vh;
   margin-bottom: 10vh;

    

    p {
        font-size: 1.4em;
    }
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
        font-family: var(--font-family1);
        margin-bottom: 5vh;
        
    }
}

.emptyVertical {
    height: 10vh;
    width: 0;
    border-left: 2px solid var(--text-color2);
}

.plat {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 2vh;
}

.titrePlat {

    display: flex;
    justify-content: space-between;
    font-size: 1.4em;
    width: 100%;
  
}

.ingredients {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    flex-direction: row;
    font-size: 1.2em;
   
    p {
        color: var(--text-color1);
        font-weight: 100;
        
    }
}

@media screen and (max-width: 991px) {
   .titrePlat {
    h4 {
        max-width: 70%;
    }

    .ingredients {
        width: 100%;
        
    }
   }
}