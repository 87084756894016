.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    border: 2px solid var(--text-color1);
    margin-top: 10%;
    margin-bottom: 5%;

    img {
        cursor: pointer;
    }

    input {
        color: var(--text-color2);
        font-family: var(--font-family2);
        font-size: 1.2em;
        background: none;
        border-right: none;
        height: 5vh;
        outline: none; 
    }

    input:focus {
        border-bottom: 2px solid var(--text-color2); 
        color: var(--text-color2);
    }
   
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid var(--text-color1);
    width: 100%;
    background-color: var(--secondary);
    padding: 1%;

    img {
        width:2%;
        object-fit: contain;
    }
}

.plat {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
   padding-top: 1%;
   padding-bottom: 1%;
}

.plat:not(:last-child) {
    border-bottom: 2px solid var(--text-color1);
  }

  .platModifier {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
   padding-top: 2%;
   padding-bottom: 2%;
}

.nom {
    display: flex;
    align-items: center;
    width: 20%;
    padding-left: 1%;
    
}

.prix {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7%;
    border-left: 2px solid var(--text-color1);
}

.ingredients {
    display: flex;
    align-items: center;
    width: 65%;
    border-left: 2px solid var(--text-color1);
    padding-left: 1%;
}

.nomModif {
    display: flex;
    align-items: center;
    background: none;
    width: 20%;
    
}

.prixModif {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7%;
    background: none;
    align-items: center;
}

 .contents {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
.ingredientModif {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1%;
  margin-bottom: 1%;
    width: 55%;

    img {
        width: 4%;
        object-fit: contain; 
    }

   
    

    .content {
        display: flex;
        width: 40%;
        justify-content: space-around;
        
        img {
            width: 9%;
            object-fit: contain;
        }
        

       input {
     
       width: 80%;
       
    } 
    }

    .contentAjout {
        display: flex;
        width: 50%;
        justify-content: space-around;
        
        img {
            width: 8%;
            object-fit: contain;
        }
        

       input {
     
       width: 70%;
       
    } 
    }
  

    
}

.icons {
    display: flex;
    justify-content: space-around;
    width: 8% ;
    border-left: 2px solid var(--text-color1);

    img {
        width: 25%;
        object-fit: contain;
    }
}

.iconsModif {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 8% ;
    margin-right: 1.5%;
    margin-top: 1%;

    img {
        width: 25%;
        object-fit: contain;
    }
}

.emptyVertical {
    width: 0;
    height: 100%;
    border-left: 2px solid var(--text-color1);
}

.ingredientAddContainer {
  display: flex; 
   width: 50%;
   align-items: center;
}

.ingredientAdd {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width:100%;

    img {
        width: 4%;
        object-fit: contain; 
        margin: 2%;
    }

    div {
        width: 45%;
        display: flex;
       justify-content: space-between;
       margin-left: 2%;
       margin-right: 2%;

       img {
        width: 8%;
        object-fit: contain; 
      
       }

        input {
            width:85%;
            margin-bottom: 2%;
        }
    }
}

.alcool {
    display: flex;
    border-left: 2px solid var(--text-color1);

    img {
        width: 0%;
    }
}

.sousTitre {
    width: 100%;
    text-align: center;
    border-bottom: 2px solid var(--text-color1);

}

.addSemaine {
    display: flex;
    flex-direction: column;
}

.noneSemaine {
    width: 100%;
    text-align: center;
    padding: 1.5%;
    border-bottom: 2px solid var(--text-color1);
}

.questionContainer {
    display: flex;
    width: 100%;
    
}


@media screen and (max-width: 991px) {

    .container{
        margin-top: 150%;

        input {
            width: 80%;
        }
    }

    .top {
        padding: 5%;

        img {
            width: 12%;
        }
    }
    .plat {
        flex-direction: column;
        align-items: center;
    }

    .nom {
        display: flex;
        justify-content: center;
        padding-left: 0;
        width: 100%;
        text-align: center;
       
    }

    .ingredients {
        display: flex;
        justify-content: center;
        width: 100%;
        border-left: none;
        padding: 5%;
        text-align: center;
        color: var(--text-color1);
        
    }

    .prix {
        border-left: none;
    }

    .icons {
        width: 100%;
        border-left: none;

        img {
            width: 12%;
            margin: 10%;
        }
    }

    .platModifier {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    }

    .nomModif {
        width: 100%;

        border-bottom: 2px solid var(--text-color1);
    }

    .contents {
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;   
        
       
    }

    

    .ingredientModif {
        margin-top: 10%;
        margin-bottom: 10%;
        width: 100%;
        
        img {
            width: 10%;
        }
      
        
     .content {
        width: 85%;
    }
      
    }

    .prixModif {
        width: 100%;
    }

    .emptyVertical {
        display: none;
    }

    .prixModif {

width: 100%;
    }

    .iconsModif {
        width: 70%;
        margin: 10%;
        img {
            width: 20%;
        }
    }

    .ingredientAddContainer {
        width: 80%;
    }

    .ingredientAdd {
        margin-top: 20%;
        margin-bottom: 20%;
        align-items: flex-end;

        div {
            img {
                width: 20%;
                
            }
        }
        
        img {
            width: 10%;
        }
    }
}

