.container {
    display: flex;
    width: 100%;
    min-height: 100vh;
}

.burger {
    display: none;
}

.leftBar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20%;
    min-height: 100vh;
    background-color: var(--secondary);
    box-shadow: 1px 1px 3px black;

}

.rightBar {
    width: 80%;
    min-height: 100vh;
    background-color: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleLeftBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 10%;
    justify-content: space-between;
    margin-top: 5vh;
    margin-bottom: 35%;
}

.categorieLeftBar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    align-items: center;
   
}

.titleRightBar {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 10vh;
    justify-content: flex-start;
    margin-top: 5vh;
    
}

.content {
    width: 90%;
    flex-direction: column;
    height: 80%;
    display: flex;
    align-items: center;

    .welcome {
        width: 100%;
    }
}



.pannel {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 20%;
    margin-top: 5%;
}

@media screen and (max-width: 991px) {
    .container {
        justify-content: center;
        font-size: 13px;
        min-height: 0;
    }

    .burger {
        display: flex;
        width: 8%;
        position: fixed;
        right: 5%;
        top: 5%;
    }

    .leftBar {
        display: none;
    }

    .titleRightBar {
        height: 18vh;
    }

    .pannel {
        flex-direction: column;
        align-items: center;
    }
}