.container {
    display: flex;
    flex-direction: column;
    height: 60vh;
    width: 100%;
    background-color: var(--primary);

    h4 {
        font-family: var(--font-family1);
    }

    

   
}

.wallpaper {
    position: relative;
    display: flex;
    background: url(../../assets/images/Wallpaper/Wallpaper_Home.webp) center center;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 90%;
    width: 100%;
    
    h5 {
        color: var(--text-color2);
        font-weight: 100;

    }

    .emptyVertical {
        height: 100% ;
        width: 0;
        border-left: 2px solid white;
    }

}

.wallpaperContent {
    display: flex;
width: 70%;
    position: relative;
    z-index: 1;
    margin-left: 5%;
    margin-top: 5vh;
    justify-content: space-between;
}

.wallpaper::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/images/Wallpaper/Wallpaper_Home.webp) center center;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(40%);
    z-index: 0;
}








.blackbar {
    display: flex;
    background-color: rgba(var(--primary));
    height: 10%;
    justify-content: center;
    font-family: var(--font-family2) ;
    font-size: 1.5em;
    align-items: center;
    color: var(--text-color1);

    a {
        color: var(--text-color1);
        cursor: pointer;
        transition: 0.3s;
    }

    a:hover {
        color: var(--text-color2);
       }
   

    .emptyVertical {
        height: 100% ;
        width: 0;
        border-left: 2px solid var(--text-color1);
    }
}

.bar {
    display: flex;
    width: 90%;
    justify-content: space-between;
}

.rightbar {
    display: flex;
    flex-direction: row;
    width: 45%;
    justify-content: space-between;
    
}

.adresse {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 30%;

    h5 {
        font-size: 1.2em;
    }
}

.contact {
    display: flex;
    flex-direction: column;
    width: 20vh;
    height: 40%;
    justify-content: space-between;
}

.title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 5vh;
}

.logo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vh;
    

    img {
        width: 25%;
    }
}



.réservation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 25%;
}

@media screen and (max-width:991px) and (orientation: portrait) {

    .container {
        height: 120vh;
    }

    .wallpaperContent {
        flex-direction: column;
        margin-bottom: 5vh;
        margin-left: 0;
        width: 100%;
        align-items: center;
    }

    .blackbar {
        height: 20%;

       a {
        width: 35vh;
  
       } 

      
    }

    .bar {
        flex-direction: column;
       height: 80%;
    
    }

    .emptyVertical {
        display: none;
    }

    .rightbar {
        flex-direction: column;
        justify-content: space-between;
        height: 75%;
    }

   

    .adresse {
        height: 25%;
        align-items: center;
       text-align: center;
    }

    .contact {
        height: 30%;
        width: 80%;
        align-items: center;
    }

    .réservation {
        height: 15%;
    }

    .title {
       align-items: center;
    }

    .logo {

        img {
            width: 15%;
        }
    }

    
}
@media screen and (orientation: landscape) and (max-width: 991px) {

    .container {
        display: flex;
        flex-direction: column;
        height: 70vh;
        width: 100%;
    
        
    
        
    
       
    }

    .wallpaper {
        position: relative;
        display: flex;
        background: url(../../assets/images/Wallpaper/Wallpaper_Home.webp) center center;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 80%;
        width: 100%;
        
        h5 {
            color: var(--text-color2);
            font-weight: 100;
    
        }
    
        .emptyVertical {
            height: 100% ;
            width: 0;
            border-left: 2px solid white;
        }
    
    }
    
    .wallpaperContent {
        display: flex;
    width: 100%;
        position: relative;
        z-index: 1;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 5vh;
        justify-content: space-between;
    }
    
    .wallpaper::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(../../assets/images/Wallpaper/Wallpaper_Home.webp) center center;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(40%);
        z-index: 0;
    }
    
    
    
    
    
    
    
    
    .blackbar {
        display: flex;
        background-color: var(--primary);
        height: 20%;
        justify-content: center;
        font-family: var(--font-family2) ;
        font-size: 1.3em;
        align-items: center;
        color: var(--text-color1);
        align-items: center;
    
        a {
            color: var(--text-color1);
        }
       
    
        .emptyVertical {
            height: 100% ;
            width: 0;
            border-left: 2px solid var(--text-color1);
        }
    }
    
    .bar {
        display: flex;
        width: 90%;
        justify-content: space-between;
        align-items: center;
    }
    
    .rightbar {
        display: flex;
        flex-direction: row;
        width: 65%;
        justify-content: space-between;
        
    }
    
    .adresse {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 70%;
        width: 35%;
        align-items: center;
        text-align: center;
        
    
        h5 {
            font-size: 1.2em;
        }
    }
    
    .contact {
        display: flex;
        flex-direction: column;
        width: 25%;
        height: 90%;
        justify-content: space-between;
        align-items: center;
    }
    
    .title {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 10vh;
        align-items: center;
    }
    
    .logo {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2vh;
        
    
        img {
            width: 15%;
        }
    }
    
    
    
    .réservation {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 45%;
        align-items: center;
    }
    
    
    }