.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10vh ;
    margin-bottom: 10vh ;
}

.title {
    display: flex;
    flex-direction: column;
    height: 5vh;
    justify-content: space-between;
    width: 10%;
    margin-right: 5vh;
}


.contents {
    display: flex;
   
}



@media screen and (max-width: 991px) and (orientation: portrait) {
    .container {
        flex-direction: column;
        align-items: flex-start;
        height: 100vh;
        justify-content: space-between;
    }
    .contents {
        flex-direction: column;
        height: 90%;
        justify-content: space-between;
        align-items: flex-start;
    }
}

@media screen and (max-width: 991px) and (orientation: landscape) {

    .container {
        margin-top: 70vh ;
        margin-bottom: 10vh ;
        flex-direction: column;
    }

   
}