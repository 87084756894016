.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10vh;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;

    h2 {
        margin-bottom: 3vh;

       
    } p {
            margin: 5vh;
        }
}

.btn {
    display: grid;
    margin: 10vh;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(2, 1fr);
grid-row-gap: 5vh;
    width: 60%;
justify-items: center;
    
}

@media screen and (max-width: 991px) and (orientation: portrait) {
    .container {
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 20vh;
    }

    .btn {
        display: flex;
        flex-direction: column;

    }
}