.btn {
    padding: 5px 15px;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.2s opacity;
    &:hover {
        transition: 0.2s opacity;
        opacity: 0.8;
    }
    &-primary {
        background-color: var(--primary);
        color: white;
        border: 2px solid var(--primary);
    }

    &-primary-reverse {
        background-color: white;
        color: var(--primary);
        border: 2px solid var(--primary);
    }
}

.card {
    border-radius: 20px;
    background-color: #fff;
}