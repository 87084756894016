.container {
    display: flex;
    position: fixed;
    bottom: 0;
    height: 100%;
    z-index: 14;
   width: 100%;
   flex-direction: column;
   justify-content: flex-end;
   backdrop-filter: blur(4px);

   a {
    width: 100%;
    height: 30%;
   }

   img {
    width: 10%;
    margin-right: 3%;
   }
}

.contents {
    display: flex;
    flex-direction: column;
    height: 70%;
    justify-content: space-between;
    align-items: flex-end;
}
.contents2 {
    display: flex;
    flex-direction: column;
    height: 60%;
    justify-content: space-between;
    align-items: flex-end;
}

.menu {
    background-color: rgba(var(--primary));
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    h2 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 15%;
        font-size: 2em;
    }
}

.menu2 {
    border-top: 2px solid var(--text-color2);
    background-color: rgba(var(--primary));
    height: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2em;
        margin: 5%;

    }
}

.titles {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70%;
}

.lastTitle {
    border-top: none;
    border-bottom: 2px solid var(--text-color2) ;
}

.firstTitle {
    border-bottom: none;
    border-top: 2px solid var(--text-color2) ;
}

.secondTitle {
    border-top: 2px solid var(--text-color2) ;
        border-bottom: 2px solid var(--text-color2) ;
}

.button {
    background-color: var(--button1);
    width: 70%;
    height: 10%;
    border: 2px solid var(--link-color);
    font-size: 1.5rem;
    color: var(--text-color2);
    font-family: var(--font-family1);
    margin-bottom: 15%;
   
}

.land {
    display: none;
}

.adminTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 15%;
}

@media screen and (orientation: landscape) {

    .container {
        img {
            width: 5%;
            margin-right: 3%;
           }
    }

   .contents {
    height: 95%;
   }

   .titles {
    height: 100%;
   }
   
   .button {
    display: none;
   }

   .button2 {
    background-color: var(--button1);
    border: 2px solid var(--link-color);
    font-size: 1.5rem;
    color: var(--text-color2);
    font-family: var(--font-family1);
    margin-bottom: 15%;
    height: 70%;
    margin-bottom: 0;
    width: 40%;
   }

   .land {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 2%;
   }

   .social {
    display: flex;
    width: 40%;
    justify-content: space-between;

    img {
        width: 20%;
    }
   }
    
    }