.container {
    display: flex;
    position: fixed;

    height: 30vh;
    right: 0;
    top : 8vh;
    z-index: 100;
}

.contents {
    width: 9vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.empty {
    width: 0;
    height: 20%;
    border-left: 2px solid white;
}

.link {
    display: flex;
    justify-content: center;
}


.icon {
    width: 70%;
}

@media screen and (max-width: 991px) and (orientation: portrait) {

    .container {
        justify-content: center;
        height: 10vh;
        width: 100%;
    }

    .contents {
        flex-direction: row;
        width: 60%;
    }

    .empty {
        display: none;
    }

    .link {
margin-left: 1vh;
margin-right: 1vh;
    }
}

@media screen and (orientation: landscape) and (max-width: 991px) {
    .container {
        display: none;
    }
    
}