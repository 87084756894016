* {
  box-sizing: border-box;
  margin : 0;
  
}

body {
 
  margin: 0;
  background-color: var(--primary);
  font-size: 16px;
  

}

html {
 
  padding: env(safe-area-inset); 
}

@media screen and (max-width : 991px ) {

  body {
  
    font-size: 13px;
  }

  .app {
    width: 100%;
  }
}

.no-scroll {
  overflow-y: hidden;
}


@media screen and (max-width : 1600px ) and (min-width: 992px ) {
  body {
  font-size: 14px;
  
}}

.app {
  min-height: 100vh;
  background-color: rgba(var(--primary));
  
}





h2 {
  font-family: var(--font-family2);
  color: var(--text-color2);
  font-weight: 100;
  font-size: 3em;
}

h3 {
  font-family: var(--font-family1);
  color: var(--text-color1);
  font-size: 1.5em
}

h4 {
  color: var(--text-color2);
  font-weight: 100;
  font-size: 1.6em;
}

p {
  font-family: var(--font-family2);
  color: var(--text-color2);
  font-weight: 100;
  font-size: 1.2em;
  line-height: 1.5;
}


ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

img {
  max-width: 100%;
}

a {
  color: var(--text-color);
  text-decoration: none;

}

.active {
    font-weight: 200;
    color: var(--text-color2);
}