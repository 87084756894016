.container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 2vh;
    cursor: pointer;

    h3 {
        margin-right: 10%;
        font-weight: 100;
    }
}

.actif {
    background-color: rgba(var(--primary));
    position: relative;
    transition: 0.3s ease-out;
    background: linear-gradient(to left, rgba(var(--primary)) 50%, var(--secondary) 50%) right;
    background-size: 200%;
    h3 {color: var(--text-color2);}
    
    
    
}

.actif::before { 
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent; 
    border-bottom: 10px solid transparent; 
    border-right: 10px solid var(--text-color2); 
    transition: 0.3s;
    color: var(--text-color2);
}
