.container {
    display: flex;
    width: 100%;
    background-color: #1F2220;
    flex-direction: column;
    align-items: center;
}

.body {
    display: flex;
    width: 90%;
    flex-direction: column;

  
   
}

.accueil {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 65vh;
    margin-top: 10vh; 
    margin-bottom: 10vh; 
}

@media screen and (max-width : 1600px) and (min-width : 992px) {

    .accueil {
        margin-top: 15vh; 
        margin-bottom: 20vh; 

    }
}

.titleAccueil {
    font-family: var(--font-family2);
}

.contentsAccueil {
    margin-top: 5vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
 
    width: 100%;
    height: 100%;

}

.pictureAccueil {
    border: 2px solid white;
    width: 45%;
    height: 100%;
}

.teamText {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 46.5%;
    padding-right: 10px;
}

.empty {
    width: 20vh;
    border-top: 2px solid white;
}

.greenBackground {
    width: 100%;
    background-color: var(--secondary);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 5vh;
}

.leftInfo {
    display: flex;
    flex-direction: column;
    height: 55vh;
    justify-content: space-between;
    width: 50%;
}

.rightInfo {
    display: flex;
    flex-direction: column;
    height: 55vh;
    justify-content: space-between;
    width: 35%;
}

@media screen and (max-width: 991px) and (orientation: portrait) {

    .body {
        width: 85%;
    }



    .accueil {
        height: 120vh;
        justify-content: space-between;
        margin-bottom: 5vh; 
    }

    .contentsAccueil {
        flex-direction: column;
    }

    .pictureAccueil {
        width: 100%;
        height: 35%;
    }

    .titleAccueil {
        margin-top: 5vh;
    }

   .teamText {
    width: 100%;
    height: 100%;
    padding-right: 0;
   }

   .info {
    flex-direction: column;
    height: 145vh;
    justify-content: space-between;
}

.leftInfo {
    height: 50%;
    width: 100%;
    margin-bottom: 5vh;
}

.rightInfo {
    height: 40%;
    width: 100%;
}
}

@media screen and (max-width: 400px) and (orientation: portrait) {
    .accueil {
        height: 150vh;
    }

    .pictureAccueil {
        width: 100%;
        height: 25%;
    }

    
   .info {
    flex-direction: column;
    height: 175vh;
    justify-content: space-between;
}
}

@media screen and (orientation: landscape) and (max-width: 991px) {
    .app{
        width: 100%;
    }
 
    .accueil {
        
        height: 240vh;
        justify-content: space-between;
        margin-bottom: 5vh; 
        
    }

    .contentsAccueil {
        flex-direction: column;
    }

    .pictureAccueil {
        width: 100%;
        height: 45%;
    }

    .titleAccueil {
        margin-top: 5vh;
    }

   .teamText {
    width: 100%;
    height: 100%;
    padding-right: 0;
   }
          
   .leftInfo {
    display: flex;
    flex-direction: column;
    height: 135vh;
    justify-content: space-between;
    width: 50%;
}

.rightInfo {
    display: flex;
    flex-direction: column;
    height: 135vh;
    justify-content: space-between;
    width: 35%;
}
   
}
