.container {
    display: flex;
    position: fixed;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    overflow-y: scroll;
    
    top: 0;

    &::-webkit-scrollbar{
      display: none;
    }
  }

  .contents {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
  .closeBtn {
    margin-top: 5vh;
    width: 98%;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    cursor: pointer;

    img {
      width: 3%;
    }
  }
  
  .imageContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    
    img {
    width: 40% ;
    margin-top: 20vh;
    margin-bottom: 20vh;
  }
  }

  .loading {
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  
  @media screen and ( max-width: 991px) and (orientation: portrait) {
   
    
    
    .closeBtn {
  
      img {
        width: 10%;
      }
    }
    
    .imageContainer {
      width: 85%;
      
      img {
      width: 80vh ;
      height: 30vh;
      margin-top: 20vh;
      margin-bottom: 20vh;
    }
    }
    
    
    
  }

  @media screen and (max-width: 991px) and (orientation: landscape) {
    .closeBtn {
     img {
      width: 5%;
     }

     
    }

    .imageContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 90%;
      
      img {
      width: 45% ;
      height: 40vh;
      margin-top: 20vh;
      margin-bottom: 20vh;
    }
    }
}