.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 2px solid var(--text-color1);
    margin-top: 10%;
    margin-bottom: 5%;
    min-height: 35%;
}

.horaire {
    display: flex;
    align-items: center;
    flex-direction: column;   
    width: 15%;
    
}

.horaire:not(:last-child) {
    border-right: 2px solid var(--text-color1);
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5%;
    width: 100%;
    background-color: var(--secondary);
    border-bottom: 2px solid var(--text-color1);
  }

  .body {
    margin: 5%;
  
  }

.icons {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-around;
    border-top: 2px solid var(--text-color1);
    align-items: center;
    
    img {
        width: 20%;
    }
}

.inputs {
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
        background-color: var(--secondary);
        color: var(--text-color2);
        border: 2px solid var(--text-color1);
    }

}


input[type="time"]::-webkit-calendar-picker-indicator{
    filter: invert(99%) sepia(9%) saturate(70%) hue-rotate(140deg) brightness(113%) contrast(100%);
  }

  .actif {
    color: var(--text-color2);
  }
  
  @media screen and (max-width: 991px) {
    .container {
        flex-direction: column;
        font-size: 12px;
        min-height: auto;
        
    }

    .horaire {
        width: 100%;
    }

    .horaire:not(:last-child) {
        border-right: none;
        .icons {
            border-bottom: 2px solid var(--text-color1);
    }
}

    .top {
       border: none;
    }

    .body {
        
    }

    .icons {
        border-top: none;
       
        
        img {
            width: 10%;
            margin-bottom: 10%;
        }
      
    }
  }