.container {
    display: flex;
    position: fixed;
    width: 100%;
    height: 8vh;
    justify-content: center;
    font-size: 20px;
    background-color: rgba(var(--primary), 0.6) ;
    z-index: 2;
}



.contents {
    display: flex;
    height: 100%;
    width: 98%;
    align-items: center;
    justify-content: space-between;
}

.linkLogo {
    display: flex;
    align-items: center;
    height: 100%;
}

.logo {
    height: 80%;
}

.navbar {
    display: flex;
    width: 50%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.link {
    font-family: var(--font-family1);
    color: var(--link-color);
    font-size: 1em;
    transition: 0.3s;

    &:hover {
        color: var(--text-color2);
    }
}

.button {
    background-color: var(--button1);
    width: 20%;
    height: 70%;
    border: 2px solid var(--link-color);
    font-size: 1.5rem;
    color: var(--text-color2);
    font-family: var(--font-family1);

   
}

.burger {
    display: none;
}

@media screen and (max-width: 991px) {

    .contents {
    display: flex;
    height: 100%;
    width: 95%;
    align-items: center;
    justify-content: space-between;
}

    .logo {
        height: 70%;
    }
    .navbar {
        display: none;
    }

    .burger {
        display: flex;
        height: 40%;
    }
}

@media screen and (orientation: landscape) and (max-width: 991px) {

    .container {
        height: 12vh;
    }
    
    }
    
