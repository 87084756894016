.container {
    display: flex;
    width: 100%;
    background-color: #1F2220;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
   
   
}


.body {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    h2 {
        margin-bottom: 5vh;
        margin-top: 10vh;
        text-align: center;
    }
}

.questions {
    position: fixed;
    bottom: 10%;
    right: 10%;
     width: 3%;
     transition: 0.3s;
     display: none;

     &:hover {
        transform: scale(1.3);
     }

}

.containerQuestions {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
   width: 100%;
   height: 100vh;
   background-color: rgba(0, 0, 0, 0.9);
   z-index: 100;
   text-align: center;

   h2 {
    margin: 3%;
   }

   p {
    font-size: 1.5em;
   }

   img {
    position: relative;
    top: 5%;
    left: 45%;
    width: 2%;
   }
}

.btnQuestions {
   display: flex;
   width: 80%;
   justify-content: space-between;
   margin-top: 10%;
    div {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 25vh;    
    height: 20vh;
    font-size: 1.7em;
    border: 2px solid var(--text-color1);  
    transition: 0.3s;

    &:hover {
        transform: scale(1.2);
        border: 2px solid var(--text-color2);
    }
    }
}

@media screen and (max-width: 991px) {
    .body {
        width: 85%;

    }
}

