.container {
    display: flex;
    background-color: rgba(var(--primary));
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
        width: 20%;
        margin-bottom: 10vh;
    }

    h1 {
        color: var(--text-color2);
        text-align: center;
        width: 80%;
    }
}

@media screen and (max-width: 991px) and (orientation: portrait) {
    .container {
        .logo {
            width: 50%;
        }

        h1 {
            font-size: 1.2rem;
            line-height: 1.5;
        }
    }
}
