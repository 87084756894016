.login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


.container {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 2px solid var(--text-color1);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondary);
  box-shadow: 3px 3px 1px var(--text-color1);
}

.title {
  text-align: center;
  font-size: 24px;
  margin: 5%;
  color: var(--text-color2);
  font-weight: 100;
}

.form {
  display: flex;
  flex-direction: column;
  margin-bottom: 5%;
  align-items: center;
  width: 100%;
}

.inputGroup {
  margin-bottom: 15%;
  width: 100%;
}

.label {
  display: block;
  font-weight: bold;
}

.input {
  height: 5vh;
  color: var(--text-color1);
  font-size: 1.2em;
  font-family: var(--font-family2);
  border: none;
  background: transparent ;
  border-bottom: 2px solid var(--text-color1);
  width: 100%;
  outline: none; 
  transition: border-bottom 0.3s ease;
  transition: color 0.3s ease;
  
}

.input:focus {
  border-bottom: 2px solid var(--text-color2); 
  color: var(--text-color2);
}

.error {
  color: var(--error);
}
