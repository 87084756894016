.button {
    display: flex;
    width: 25vh;
    height: 5vh;
    border: 2px solid white;
    margin: 0;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
        background-color: var(--hover);
        cursor: pointer;
    }

    button {
        width: 100%;
        border: none;
        background : none;
        font-family: var(--font-family1);
        color: var(--text-color2);
        font-weight: 100;
        font-size: 1em;
        
    }

    
}

.button2 {
    width: 25vh;
    border: 2px solid white;
    height: 5vh;
    background : none;
    font-family: var(--font-family1);
    color: var(--text-color2);
    font-weight: 100;
    font-size: 1em;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
        background-color: var(--hover);
        cursor: pointer;
    }
}

@media screen and (max-width : 991px) and (orientation: portrait) {
    .button {
        width: 25vh;
    }
}

@media screen and (orientation: landscape) and (max-width: 991px) {

   .button {
    width: 40vh;
    height: 10vh ;
   }
    
    }