.container {
    display: flex;
    flex-direction: column;
    
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h5 {
        color: var(--text-color1);
        font-size: 1.2em;
    }
}


.contents {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 60%;

    img {
        width: 25%;
    }
}

.contents2 {
    display: none;

    img {
        display: none;
    }
}

@media screen and (max-width: 991px) and (orientation: portrait) {
.container {
    align-items: center;
    
}

.contents {
   
    align-items: center;
    img {
        width: 20%;
    }
}
.contents2 {
    display: none;
}
}

@media screen and (orientation: landscape) and (max-width: 991px) {

    .container {
margin-top: 5%;
    }

    .contents {
        display: none;
    }

    .contents2 {
        text-align: center;
  
        img {
            width: 50%;
        }
    }
     
     }