.d-flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.flex-fill {
    flex: 1 1 auto;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: end;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-around {
    justify-content: space-around;
}

.justify-content-evently {
    justify-content: space-evenly;
}

.align-items-center {
    align-items: center;
}

.align-items-end {
    align-items: end;
}

.align-items-around {
    align-content: space-around;
}