.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 20%;
    margin-bottom: 5vh;
    z-index: 1;
}



.title {
    color: var(--text-color2);
    margin-bottom: 10vh;
    z-index: 1;
    text-align: center;
}
.title2 {
    color: var(--text-color2);
    z-index: 1;
    font-size: 3.5em;
}

.Homepage {
    background: url(../../assets/images/Wallpaper/Wallpaper_Home.webp) center center;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
   
}

.Homepage::after {
   
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: url(../../assets/images/Wallpaper/Wallpaper_Home.webp) center center;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(40%);
    z-index: 0;

}

.Contact { background: url(../../assets/images/Wallpaper/Wallpaper_Contact.jpg) center center;
    background-attachment: fixed;
    background-position: 0 150%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;}

    .Contact::after {
   
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50vh;
        background: url(../../assets/images/Wallpaper/Wallpaper_Contact.jpg) center center;
        background-attachment: fixed;
        background-position: 0 150%;
        background-repeat: no-repeat;
        background-size: cover;
        filter: brightness(40%);
        z-index: 0;
    
    }

    .Photos { background: url(../../assets/images/Wallpaper/Wallpaper_Photos.webp) center center;
        background-attachment: fixed;
        background-position: 0 150%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 50vh;}
    
        .Photos::after {
       
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 50vh;
            background: url(../../assets/images/Wallpaper/Wallpaper_Photos.webp) center center;
            background-attachment: fixed;
            background-position: 0 150%;
            background-repeat: no-repeat;
            background-size: cover;
            filter: brightness(40%);
            z-index: 0;
        
        }

        .Menu { background: url(../../assets/images/Wallpaper/Wallpaper_Menu.jpg) center center;
            background-attachment: fixed;
            background-position: 0 150%;
            background-repeat: no-repeat;
            background-size: cover;
            height: 50vh;}
        
            .Menu::after {
           
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 50vh;
                background: url(../../assets/images/Wallpaper/Wallpaper_Menu.jpg) center center;
                background-attachment: fixed;
                background-position: 0 150%;
                background-repeat: no-repeat;
                background-size: cover;
                filter: brightness(40%);
                z-index: 0;
            
            }


        @media screen and (max-width: 991px) and (orientation: portrait) {
            .logo {
                width: 60%;
            }
            
        }

       